import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import Zoom from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AddCircleOutlineOutlined from '@material-ui/icons/AddCircleOutlineOutlined';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { default as DeleteOutlined, default as DeleteOutlinedIcon } from '@material-ui/icons/DeleteOutlined';
import EventNoteIcon from '@material-ui/icons/EventNote';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import MicRoundedIcon from '@material-ui/icons/MicRounded';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ShareIcon from '@material-ui/icons/Share';
import VerticalAlignBottomRoundedIcon from '@material-ui/icons/VerticalAlignBottomRounded';
import format from 'date-fns/format';
import { sl } from 'date-fns/locale';
import { saveAs } from 'file-saver';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewLabelToSession,
  cancelRetranscription,
  copySession,
  deleteSession,
  getAllDataAboutSessionAdmin,
  getClientLabels,
  getSessionLabels,
  getSessionRecordings,
  patchSessionInfo,
  registerNewClientLabel,
  removeLabelFromSession,
  startRetranscription,
  updateSessionLabel,
} from '../../api/SessionsService';
import useIsRole from '../../hooks/useIsRole';
import { setDashboardExpendedRow, setDashboardRefresh, setSessionsLabels } from '../../redux/actions/actions';
import { DashboardRefreshState, ISessionLabel, IStore, UserRoleEnums } from '../../redux/store/IStore';
import { convertSecondsToTimeString } from '../../shared/DataConverters';
import Chip from './ColouredChip';
import DeletionConfirmation from './DeletionConfirmation';
import ShareModal from './ShareModal';
import { IChipData, IFetchedData, ISavableData, ISessionEntry } from './IDashboard';
import moment from 'moment';
import { RestartIcon } from '../Icons/RestartIcon';
import { CancelIcon } from '../Icons/CancelIcon';
import { SessionState } from '../DashboardHeader/ISearch';

interface IProps {
  showSkeleton?: boolean;
  row: ISessionEntry;
  handleRowClick: (id: number, sessionName: string) => void;
  fetchedData: IFetchedData;
  setParentTitle: React.Dispatch<React.SetStateAction<string>>;
  setParentChips: React.Dispatch<React.SetStateAction<IChipData[]>>;
  setSharedWithOthers: React.Dispatch<React.SetStateAction<boolean>>;
}

const tempColours = ['#264653', '#2a9d8f', '#e9c46a', '#f4a261', '#e76f51'];

const NoBorderTextField = withStyles({
  root: {
    '& .MuiInput-underline:before': {
      borderBottom: '0px solid green !important',
    },
    '& .MuiInput-root': {
      marginTop: '0px',
      paddingTop: '0px',
    },
  },
})(TextField);

const useStylesEditMode = makeStyles(() =>
  createStyles({
    root: {
      fontSize: '24px',
      fontWeight: 400,
      paddingTop: '22px',
    },
    textarea: {
      marginTop: '0px',
      paddingTop: '0px',
      fontSize: '16px',
    },
    paddingoutline: {
      '& .MuiOutlinedInput-input': {
        padding: '8px',
      },
    },
    roundedcornes: {
      borderRadius: '5px !important',
    },
    disablecorner: {
      borderRadius: '0px',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    savingwrapper: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
    },
    maxwidthtooltip: {
      maxWidth: 'none',
    },
    iconButton: {
      '&:disabled': {
        opacity: 0.4,
      },
    },
  })
);

const TableRowAccordion = ({
  handleRowClick,
  row,
  fetchedData: init,
  setParentTitle,
  setParentChips,
  showSkeleton,
  setSharedWithOthers,
}: IProps) => {
  const classes = useStylesEditMode();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const user = useSelector((state: IStore) => state.user);

  const availableLabels = useSelector((state: IStore) => state.sessionsLabels) as ISessionLabel[];

  const [anchorElAddLabel, setAnchorElAddLabel] = useState<null | HTMLElement>(null);
  
  const [deletionConfirmationModal, setDeletionConfirmationModal] = useState<boolean>(false);
  const toggleDeletion = () =>  setDeletionConfirmationModal(!deletionConfirmationModal)

  const [shareModal, setShareModal] = useState<boolean>(false); // Control the 'Share with others' modal

  const [chips, setChips] = useState<IChipData[]>(init.chips);
  const [title, setTitle] = useState<string>(init.title);
  const [notes, setNotes] = useState<string>(init.notes);

  const [newLabel, setNewLabel] = useState<string>('');

  const [isSaving, setIsSaving] = useState(false); // Is the save request currently in transaction?
  const [shouldSave, setShouldSave] = useState(false); // Are there changes to be saved to server?
  const [titleWidth, setTitleWidth] = useState(100);

  const [isAdmin] = useIsRole(UserRoleEnums.ADMIN);
  const isHardDeleter = user ? user.userRoles.includes(UserRoleEnums.HARD_DELETER) : false

  const obj: ISavableData = {
    notes: init.notes,
    title: init.title,
  };

  const [referenceData, setReferenceData] = useState<ISavableData>(obj); // This is the data we will be doing checks againts -> initial data from server that is
  const [savableData, setSavableData] = useState<ISavableData>(obj); // This is the data we weill be sending to server

  // When users confirms deletion on delete modal
  const deleteSessionCallback = () => {
    toggleDeletion();
    deleteSession(row.id, row.isDiscarded && isHardDeleter).then(() => {
      dispatch(setDashboardExpendedRow(-1));

      dispatch(setDashboardRefresh(DashboardRefreshState.NORMAL));

      enqueueSnackbar('Seja uspešno izbrisana.', {
        variant: 'success',
      });
    });
  };

  useEffect(() => {
    setTitleWidth(title.length * 14);
  }, [title]);

  // Save function -> First checks if it actually needs to save to server, before saving!
  const save = useCallback(() => {
    const checkForSaveTitle = () => {
      if (referenceData.title.length !== title.length) return true;
      if (referenceData.title !== title) return true;
      return false;
    };

    const checkForSaveNotes = () => {
      if (referenceData.notes.length !== notes.length) return true;
      if (referenceData.notes !== notes) return true;
      return false;
    };

    if (!checkForSaveTitle() && !checkForSaveNotes()) {
      setShouldSave(false);
      return;
    }

    setSavableData({
      title,
      notes,
    });
  }, [title, notes, referenceData]);

  // After user stops typing for 1 second, autosave it
  useEffect(() => {
    const autosave = setTimeout(() => {
      save();
    }, 1000);

    return () => clearTimeout(autosave);
  }, [title, notes, save]);

  // Used for when the user clicks away when the Add Label popover is opened
  const clickAwayHandler = () => {
    if (anchorElAddLabel) setAnchorElAddLabel(null);
  };

  // Admin only
  const adminDownloadData = () => {
    getAllDataAboutSessionAdmin(row.id)
      .then(
        ([
          { data: dSession },
          { data: dSummary },
          {
            data: { content: dRecordings },
          },
          { data: dTranscripts },
        ]) => {
          const printable = {
            session: { ...dSession, ...dSummary },
            recordings: dRecordings,
            transcripts: dTranscripts.map(({ id, content }: any) => ({
              id,
              content: JSON.parse(content),
            })),
          };

          var data = new Blob([JSON.stringify(printable, null, 4)], { type: 'application/json' });

          saveAs(data, `truebar-${row.id}.json`);
        }
      )
      .catch((err) => {
        console.error(err);
        enqueueSnackbar('Prenos podatkov neuspešen.', {
          variant: 'error',
        });
      });
  };

  const duplicateSession = () => {
    copySession(row.id).then(() => {
      dispatch(setDashboardRefresh(DashboardRefreshState.NORMAL));

      enqueueSnackbar('Seja uspešno podvojena.', {
        variant: 'success',
      });
    });
  };

  const addLabelClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!anchorElAddLabel) {
      const target = event.currentTarget;
      getClientLabels().then(({ data }) => {
        dispatch(setSessionsLabels(data));
        setAnchorElAddLabel(target);
      });
    } else setAnchorElAddLabel(null);
  };

  const chipClick = (labelId: number, isEnabled: boolean) => {
    updateSessionLabel(row.id, labelId, isEnabled).then(() => {
      setChips((old) => old.map((row) => (row.label.id === labelId ? { ...row, isEnabled } : row)));
    });
  };

  const removeLabel = (labelIdToDelete: number) => {
    removeLabelFromSession(row.id, labelIdToDelete).then(() => {
      setAnchorElAddLabel(null);
      setChips((old) => {
        const newChips = old.filter(({ label: { id } }) => id !== labelIdToDelete);
        return newChips;
      });
    });
  };

  const generateColor = () => {
    return tempColours[availableLabels.length % tempColours.length];
  };

  const addNewLabel = (labelId: number, code: string, color: string) => {
    addNewLabelToSession(row.id, labelId, true).then(() => {
      setChips((old) => {
        const newChip: IChipData = {
          isEnabled: true,
          label: {
            id: labelId,
            code,
            color,
          },
        };

        const newChips = [...old, newChip];
        return newChips;
      });

      setNewLabel('');
      setAnchorElAddLabel(null);
    });
  };

  const registerNewLabel = () => {
    const color = generateColor();

    if (
      availableLabels.some(({ code }) => code === newLabel) ||
      chips.some(({ label: { code } }) => code === newLabel)
    ) {
      enqueueSnackbar(`Labela "${newLabel}" že obstaja.`, {
        variant: 'error',
        autoHideDuration: 4000,
      });

      return;
    }

    registerNewClientLabel(newLabel, color)
      .then(({ data: { id } }) => addNewLabel(id, newLabel, color))
      .catch((err) => {
        const labelId = err.response?.data?.id;

        if (labelId) {
          setNewLabel('');
          addNewLabelToSession(row.id, labelId, true).then(() => {
            getSessionLabels(row.id).then(({ data }) => {
              setChips(data);
            });
          });
        }
      });
  };

  const addButtonOpen = Boolean(anchorElAddLabel);
  const addButtonId = addButtonOpen ? 'simple-popper' : undefined;

  useEffect(() => {
    setShouldSave(true);
  }, [title, notes]);

  useEffect(() => {
    if (JSON.stringify(savableData) === JSON.stringify(referenceData)) return;
    const oldSavableData: ISavableData = JSON.parse(JSON.stringify(savableData));

    setIsSaving(true);

    patchSessionInfo({ name: oldSavableData.title, notes: oldSavableData.notes }, row.id)
      .then(() => {
        setParentTitle(oldSavableData.title);
        setReferenceData(oldSavableData); // Razen ce bomo dobili nazaj s serverja stuff :)
      })
      .catch(() => {
        enqueueSnackbar('Napaka pri shranjevanju na strežnik!', {
          variant: 'error',
        });
      })
      .finally(() => {
        setIsSaving(false);
      });
  }, [savableData, enqueueSnackbar, referenceData, setParentTitle, row.id]);

  // Whenever savable data and reference data changes, check for updates. Not really needed, but takes
  // care of edge cases.
  useEffect(() => {
    setShouldSave(JSON.stringify(savableData) !== JSON.stringify(referenceData));
  }, [referenceData, savableData]);

  // When chips are updated, we also update the parent chips
  useEffect(() => setParentChips(chips), [chips, setParentChips]);

  const generateSourcesText = () => {
    if (!init.sources) return '';

    let text = 'Posnetek je bil ustvarjen preko';

    init.sources.forEach((s, i) => {
      text = `${text}${i !== 0 ? ',' : ''} ${s}`;
    });

    return text;
  };

  const retranscribe = async () => {
    try {
      await startRetranscription(row.id)
      dispatch(setDashboardRefresh(DashboardRefreshState.ZERO));
    } catch (error) {
      enqueueSnackbar(`Pri retranskripciji je prišlo do napake. Poskusite ponovno.`, {
        variant: 'error',
      });
    }
  }

  const cancelRetranscribing = async () => {
    try {
      await cancelRetranscription(row.id)
      dispatch(setDashboardRefresh(DashboardRefreshState.ZERO));
    } catch (error) {
      enqueueSnackbar(`Pri prekinitvi retranskripcije je prišlo do napake. Poskusite ponovno.`, {
        variant: 'error',
      });
    }
  }

  return (
    <div className="row-accordion">
      <div className="accordion-header">
        {isAdmin && (
          <Tooltip title={generateSourcesText()}>
            {init.isSaved ? (
              <MenuBookIcon style={{ marginRight: '10px', color: '#62A9FB', marginTop: '12px' }} />
            ) : (
              <CloudUploadIcon style={{ marginRight: '10px', color: '#62A9FB', marginTop: '12px' }} />
            )}
          </Tooltip>
        )}

        <NoBorderTextField
          required
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          InputProps={{
            classes: {
              input: classes.root,
            },
            spellCheck: false,
          }}
          style={{ width: titleWidth, minWidth: '200px', maxWidth: '600px' }}
          onBlur={save}
        />

        <div style={{ flexGrow: 1 }}></div>

        {(row.status === SessionState.FINISHED || row.status === SessionState.ERROR || row.status === SessionState.CANCELED) && (
          <Tooltip title="Ponovno transkribiraj">
            <IconButton aria-label="copy" onClick={retranscribe}>
              <RestartIcon className="retranscribe-icon" />
            </IconButton>
          </Tooltip>
        )}

        {row.status === SessionState.IN_QUEUE && (
          <Tooltip title="Prekini transkribiranje">
            <IconButton aria-label="copy" onClick={cancelRetranscribing}>
              <CancelIcon className="cancel-retranscribe-icon" />
            </IconButton>
          </Tooltip>
        )}

        {isAdmin && (
          <Tooltip title="Prenesi JSON">
            <IconButton aria-label="copy" onClick={adminDownloadData}>
              <VerticalAlignBottomRoundedIcon className="accordion-header-icon" />
            </IconButton>
          </Tooltip>
        )}

        <Tooltip title="Deli">
          <IconButton
            aria-label="copy"
            onClick={() => setShareModal(true)}
            disabled={row.sharedWithMe}
            className={classes.iconButton}
          >
            <ShareIcon className="accordion-header-icon" />
          </IconButton>
        </Tooltip>

        <Tooltip title="Podvoji">
          <IconButton aria-label="copy" onClick={duplicateSession}>
            <FileCopyOutlinedIcon className="accordion-header-icon" />
          </IconButton>
        </Tooltip>

        {(!row.isDiscarded || (row.isDiscarded && isHardDeleter)) && (
          <Tooltip title="Izbriši projekt">
            <IconButton aria-label="delete" onClick={toggleDeletion}>
              <DeleteOutlinedIcon className="accordion-header-icon accordion-header-delete-icon" />
            </IconButton>
          </Tooltip>
        )}
      </div>

      {/* Datum ustvarjenja */}
      <div className="accordion-body">
        <div className="accordion-body-container session-metadata">
          <Tooltip title="Ura in čas ustvarjanja">
            <div style={{ display: 'flex' }}>
              <CalendarTodayIcon fontSize="small" style={{ padding: '1px', opacity: '0.8' }} />
              <Typography variant="body2" style={{ fontSize: '16px', marginLeft: '8px' }}>
                {format(new Date(init.createdAt), 'dd. LLLL yyyy HH:mm:ss', { locale: sl })}
              </Typography>
            </div>
          </Tooltip>
        </div>

        {/* Čas trajanja */}
        <div className="accordion-body-container session-metadata">
          <Tooltip title="Čas trajanja">
            <div style={{ display: 'flex' }}>
              <ScheduleIcon fontSize="small" style={{ padding: '1px', opacity: '0.8' }} />
              <Typography variant="body2" style={{ fontSize: '16px', marginLeft: '8px' }}>
                {convertSecondsToTimeString(init.recordedSeconds, false)}
              </Typography>
            </div>
          </Tooltip>
        </div>

        {/* Število snemanj */}
        <div className="accordion-body-container session-metadata">
          <Tooltip classes={{ tooltip: classes.maxwidthtooltip }} title={<AdminTooltip sessionId={row.id} />}>
            <div style={{ display: 'flex' }}>
              <MicRoundedIcon fontSize="small" style={{ padding: '1px', opacity: '0.8' }} />
              <Typography variant="body2" style={{ fontSize: '16px', marginLeft: '8px' }}>
                {init.numRecordings}
              </Typography>
            </div>
          </Tooltip>
        </div>

        {/* Zadnja sprememba */}
        <div className="accordion-body-container session-metadata">
          <Tooltip title="Zadnja sprememba">
            <div style={{ display: 'flex' }}>
              <EventNoteIcon fontSize="small" style={{ padding: '1px', opacity: '0.8' }} />
              <Typography variant="body2" style={{ fontSize: '16px', marginLeft: '8px' }}>
                {format(new Date(init.updatedAt), 'dd. LLLL yyyy HH:mm:ss', { locale: sl })}
              </Typography>
            </div>
          </Tooltip>
        </div>
      </div>

      <div className="accordion-chip-container">
        {chips.map(({ isEnabled, label }) => {
          return (
            <Chip
              customColor={label.color}
              key={label.id}
              label={label.code}
              variant={isEnabled ? 'default' : 'outlined'}
              size="small"
              onClick={() => chipClick(label.id, !isEnabled)}
              clickable
              onDelete={() => removeLabel(label.id)}
            />
          );
        })}

        <Tooltip title={!addButtonOpen ? 'Dodaj novo' : ''} enterDelay={500} TransitionComponent={Zoom}>
          <IconButton
            aria-describedby={addButtonId}
            aria-label="addnewlabel"
            style={{ padding: '0px' }}
            onClick={addLabelClick}
          >
            <AddCircleOutlineIcon color="primary" fontSize="small" />
          </IconButton>
        </Tooltip>

        <Popper
          id={addButtonId}
          open={addButtonOpen}
          anchorEl={anchorElAddLabel}
          placement="right"
          disablePortal={false}
          style={{ zIndex: 100000 }}
          modifiers={{
            flip: {
              enabled: true,
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: 'scrollParent',
            },
          }}
          transition
        >
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={clickAwayHandler}>
              <Zoom {...TransitionProps} timeout={350}>
                <Paper
                  elevation={3}
                  className="accordion-chip-popper-container"
                  style={{ marginLeft: '-5px' }}
                  square={false}
                  classes={{ rounded: classes.roundedcornes }}
                >
                  {availableLabels
                    .filter(({ isDefault, isAssigned }) => isDefault || isAssigned)
                    .filter(({ id }) => !chips.some(({ label: { id: _id } }) => _id === id))
                    .map(({ id, code, color }) => (
                      <MenuItem key={id} onClick={() => addNewLabel(id, code, color)}>
                        <div className="accordion-chip-popper-dot" style={{ backgroundColor: color }} />
                        {code}
                      </MenuItem>
                    ))}

                  <div className="accordion-chip-popper-footer">
                    <AddCircleOutlineOutlined
                      fontSize="small"
                      className="accordion-chip-popper-footer-add"
                      style={{ color: generateColor() }}
                    />
                    <TextField
                      fullWidth
                      placeholder="Nova labela"
                      value={newLabel}
                      spellCheck={false}
                      onChange={(e) => setNewLabel(e.target.value)}
                      variant="outlined"
                      classes={{ root: classes.paddingoutline }}
                      onKeyPress={(ev) => {
                        if (ev.key === 'Enter') registerNewLabel();
                      }}
                    />
                  </div>
                </Paper>
              </Zoom>
            </ClickAwayListener>
          )}
        </Popper>
      </div>

      <div className="accordion-body">
        <div className="accordion-body-container">
          <div className="accordion-body-sub-headers"> Predogled </div>

          <Typography
            variant="body2"
            color={init.summary === '' ? 'textSecondary' : 'initial'}
            style={{ fontSize: '16px' }}
          >
            {init.summary === '' ? 'Predogled je prazen.' : init.summary}
          </Typography>
        </div>

        <div className="accordion-body-container">
          <div className="accordion-body-sub-headers"> Zapiski </div>
          <NoBorderTextField
            multiline
            fullWidth
            maxRows={6}
            onBlur={save}
            value={notes}
            placeholder="Dodaj zapiske"
            onChange={(e) => setNotes(e.target.value)}
            InputProps={{
              classes: {
                input: classes.textarea,
              },
              spellCheck: false,
            }}
          />
        </div>
      </div>

      <Divider />

      <div className="accordion-footer">
        <div className={classes.savingwrapper}>
          <div style={{ marginRight: '10px' }}>
            {isSaving || shouldSave ? (
              <CircularProgress size={16} />
            ) : (
              <CheckRoundedIcon style={{ width: '16px', height: '16px' }} />
            )}
          </div>

          <Typography style={{ fontSize: '16px' }}>
            {isSaving || shouldSave ? 'Shranjujem...' : 'Shranjeno'}
          </Typography>
        </div>

        <Button
          variant="contained"
          disableElevation
          classes={{ root: classes.disablecorner }}
          onClick={() => handleRowClick(row.id, referenceData.title)}
          color="primary"
        >
          Odpri
        </Button>
      </div>

      <DeletionConfirmation
        hardDelete={isHardDeleter || (row.isDiscarded && isHardDeleter)}
        open={deletionConfirmationModal}
        handleClose={toggleDeletion}
        deleteSessionCallback={deleteSessionCallback}
      />

      <ShareModal
        open={shareModal}
        handleClose={setShareModal}
        modalTitle={title}
        sessionId={row.id}
        setSharedWithOthers={setSharedWithOthers}
      />
    </div>
  );
};

const AdminTooltip = ({ sessionId }: { sessionId: number }) => {
  const [content, setContent] = useState<any[]>([]);

  useEffect(() => {
    getSessionRecordings(sessionId).then(({ data: { content } }) => {
      setContent(content);
    });
  }, [sessionId]);

  return (
    <div
      style={{
        width: "550px",
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
      }}
    >
      {content.map(
        (recording : any) => {
          const {
            id,
            transcriptionLanguage,
            transcriptionDomain,
            transcriptionModelVersion,
            createdAt,
            duration
          } = recording

          return (
            <div key={id} style={{ display: 'flex', alignItems: 'center' }}>
              {recording.del ? <DeleteOutlined fontSize="small" /> : <MicRoundedIcon fontSize="small" />}
              <div style={{ fontSize: '12px', marginLeft: '10px' }}>
              id: {id}, dolžina: {convertSecondsToTimeString(duration, false)}s, model: {transcriptionLanguage}:{transcriptionDomain}:{transcriptionModelVersion}, posneto: {moment(createdAt).format("DD.MM.YYYY HH:mm")}
              </div>
            </div>
          )
          }
      )}
    </div>
  );
};

export default TableRowAccordion;
