import { AxiosResponse } from "axios";
import { config } from "../config";
import { API, CLIENT, UPLOAD } from "./endpoints";
import axiosInstance from './axios';

const backendUrl: string = config.backendApiUrl;
const baseUrl = `${backendUrl}/${API}/${CLIENT}/${UPLOAD}?async=true`;

export const processFile = (file: any, updateProgress: (value: number) => void): Promise<AxiosResponse<any>> => {
    var data = new FormData();
    data.append("file", file)
    return axiosInstance.post(`${baseUrl}`, data, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        timeout: 1000 * 60 * 10,
        onUploadProgress: (event) => {
            updateProgress(event.loaded/event.total)
        },
    });
};